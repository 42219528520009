<script setup lang="ts">
import type { MarketingStripProps, Messages } from './MarketingStrip.props'

const props = defineProps<MarketingStripProps>()

const stripMaxHeight = useCssVar('--marketing-strip-max-height')
const { dispatchHeaderEvent } = useGANavigationEvents()

// This code is needed to animate the megalogo in homepage
watch(
  () => props.isHidden,
  newVal => {
    stripMaxHeight.value = newVal ? '' : '0px'
  }
)

// to use for a potential color control from CMS (:class="[chooseColorsBg, chooseColorsText]")

// const chooseColorsBg = computed(() => {
//   if (props.chooseBgColor === '' || props.chooseBgColor === undefined) {
//     if (props.isWhite) {
//       return 'bg-neutral-white'
//     } else {
//       return 'bg-neutral-black'
//     }
//   } else {
//     return 'chooseBg'
//   }
// })
// const chooseColorsText = computed(() => {
//   if (props.chooseTextColor === '' || props.chooseTextColor === undefined) {
//     if (props.isWhite) {
//       return 'text-neutral-black'
//     } else {
//       return 'text-neutral-white'
//     }
//   } else {
//     return 'chooseText'
//   }
// })

const currentIndex = ref(0)

// animate every 5 seconds
const animate = () => {
  setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % props?.messages!.length
  }, 5000)
}

const handleMdClick = (e: Event, { _meta }: Messages) => {
  const target = e.target as HTMLElement
  if (target.tagName.toLowerCase() !== 'a') return
  dispatchHeaderEvent(_meta.name ?? '')
}

onMounted(() => {
  // if on client side
  if (useEnv().isClient.value) {
    animate()
  }
})
</script>

<template>
  <div>
    <transition name="toggle-stripe-visibility">
      <div
        v-show="!isHidden && messages"
        class="bg-background-primary text-text-secondary relative flex h-[var(--marketing-strip-height)] items-center justify-center overflow-hidden"
      >
        <template
          v-for="(message, index) in messages"
          :key="message._meta.name"
        >
          <TransitionGroup name="slide-in-out-layer">
            <UtilsMarkdown
              v-if="index === currentIndex && message.message"
              :content="message.message"
              class="text-book-7 positive-padding absolute line-clamp-2 text-center md:line-clamp-1"
              :data-tracking="`CTA_${message._meta.name}`"
              pos-tracking="strips"
              @click="e => handleMdClick(e, message)"
            />
          </TransitionGroup>
        </template>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.toggle-stripe-visibility-leave-from,
.toggle-stripe-visibility-enter-to {
  margin-top: 0; /* default */
}

.toggle-stripe-visibility-enter-from,
.toggle-stripe-visibility-leave-to {
  margin-top: calc(-1 * var(--marketing-strip-max-height)); /* move it up */
}

.slide-in-out-layer-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-in-out-layer-leave-active {
  transition: all 0.25s ease-out;
}

.slide-in-out-layer-enter-from {
  opacity: 0;
  transform: translateY(100%);
}

.slide-in-out-layer-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>
